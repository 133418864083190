import { FC, Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import styled from 'styled-components';

import { useMsal } from '@azure/msal-react';
import { AccountTreeOutlined, LogoutOutlined } from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';

import { AppDispatch } from '../../store/root';
import { fetchUserMe, getUserMe } from '../../store/users';

import packageJson from '../../../package.json';
import { filterRoles } from '../../utils';
import { AccountInfo } from '../accountInfo';

const StyledNavigation = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 200px;
  height: 100vh;
  background: ${(props) => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  z-index: 999;
`;

const LogoImage = styled.img`
  height: 100%;
  width: auto;
  margin: auto;
  cursor: pointer;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 0px 20px;
  box-sizing: border-box;
`;

const NavigationItem = styled.div<{ sub?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  padding-left: ${(props) => (props.sub ? '40px' : '0')};
  width: 100%;
  box-sizing: border-box;

  position: relative;
  font-family: ${(props) => props.theme.fonts[0]};

  cursor: ${(props) => (!!props.onClick ? 'pointer' : 'initial')};

  &::after {
    position: absolute;
    content: '';
    top: 0px;
    left: -20px;
    right: 100%;
    width: 0;
    height: 50px;
    background: rgba(0, 116, 189, 0.3);
    transition: all 0.4s;
  }

  &:hover {
    &::after {
      width: ${(props) => (!!props.onClick ? 'calc(100% + 40px)' : '0')};
      transition: all 0.4s;
    }
  }
`;

const NavigationItemLabel = styled.p`
  margin-left: 20px;
  word-break: break-word;
`;

interface IMainMenuItem {
  icon: FC;
  path?: string;
  label: string;
  forRoles?: string[];
  subItems: Omit<IMainMenuItem, 'icon' | 'subItems'>[];
}

const mainMenuItems: IMainMenuItem[] = [
  {
    icon: AccountTreeOutlined,
    label: 'Snags',
    forRoles: ['ALL'],
    subItems: [
      {
        label: 'Walkdowns',
        path: '/snags/walkdowns',
        forRoles: ['ALL'],
      },
    ],
  },
  {
    icon: AccountTreeOutlined,
    label: 'Admin',
    forRoles: ['ADMIN'],
    subItems: [
      {
        label: 'Master Data',
        path: '/workflows/NCR/admin/master-data',
        forRoles: ['ADMIN'],
      },
      {
        label: 'Permissions',
        path: '/workflows/NCR/permissions',
        forRoles: ['ADMIN'],
      },
    ],
  },
];

const Navigation: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { instance } = useMsal();

  const user = useSelector(getUserMe);

  useEffect(() => {
    dispatch(fetchUserMe());
  }, [dispatch]);

  const handleNavigation = (to: string) => () => {
    navigate(`${to}`);
  };

  const handleLogout = () => {
    try {
      instance.logoutRedirect();
    } catch (err) {
      console.error(err);
    }
  };

  const filterRolesFunc = filterRoles(user);

  const renderMainMenuItems = mainMenuItems
    .filter((route) => filterRolesFunc(route.forRoles))
    .map((item) => {
      const handleClick = () => {
        handleNavigation(item.path);
      };

      const renderSubItems = item.subItems
        .filter((route) => filterRolesFunc(route.forRoles))
        .map((sItem) => {
          return (
            <NavigationItem key={sItem.label} onClick={handleNavigation(sItem.path)} sub>
              <NavigationItemLabel>{sItem.label}</NavigationItemLabel>
            </NavigationItem>
          );
        });

      const margin = Boolean(item.subItems.length) ? '0' : '20px';

      return (
        <Fragment key={item.label}>
          <NavigationItem
            key={item.label}
            onClick={item.path && handleClick}
            style={{ marginBottom: margin, cursor: item?.path ? 'cursor' : 'initial' }}
          >
            <item.icon />
            <NavigationItemLabel>{item.label}</NavigationItemLabel>
          </NavigationItem>
          {item.subItems.length > 0 && renderSubItems}
        </Fragment>
      );
    });

  return (
    <StyledNavigation>
      <Column>
        <NavigationItem style={{ marginBottom: '40px' }}>
          <LogoImage src="/icons/VfLogo.svg" onClick={handleNavigation('/')} />
        </NavigationItem>
        {renderMainMenuItems}
      </Column>
      <Column>
        <NavigationItem onClick={handleLogout} style={{ cursor: 'pointer' }}>
          <IconButton>
            <LogoutOutlined />
          </IconButton>
          <NavigationItemLabel>Logout</NavigationItemLabel>
        </NavigationItem>
        <NavigationItem>
          <AccountInfo />
          <NavigationItemLabel>{user.displayName}</NavigationItemLabel>
        </NavigationItem>
        <Typography variant="subtitle2" color="gray">
          Host version: {packageJson.version}
        </Typography>
      </Column>
    </StyledNavigation>
  );
};

export { Navigation };
